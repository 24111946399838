import { mutateGraphQL } from '@/data/apollo';
import { ClientCreditWrite } from '../../../../../data/commerce/clientCredit.graphql';
import { ClientCredit, MutationClientCreditWriteArgs } from '../../../../../types/schema';

export const deletePinCode = async ( credits: ClientCredit[], credit: ClientCredit ) => {
	await mutateGraphQL<MutationClientCreditWriteArgs>( {
		mutation : ClientCreditWrite,
		variables: { id: credit.id, remove: true },
	} );
	
	return credits.filter( ( item ) => item.id !== credit.id );
};

export const getEditedCredits = ( credits: ClientCredit[], credit: ClientCredit, index: number ) => {
	const newValue = [ ...credits ];
	newValue[ index ] = credit;
	return newValue;
};
