import axios from 'axios';
import { differenceWith, intersectionWith, isEmpty, omit } from 'lodash-es';
import { Client, Gateway } from '../../../../types/schema';

export async function postClient( clientId: string, gateway?: Gateway ) {
	await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/postClient`, {
		id       : clientId,
		gatewayId: gateway?.id,
	} );
}

export async function importClient( clientId: string ) {
	await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/importClient`, { id: clientId } );
}

export function getClientAddressesToSave( clientAddresses: any[], dbClient?: Client ) {
	const dbAddresses = dbClient?.addresses;
	let addressesToSave: any[] = [];
	if ( isEmpty( dbAddresses ) ) {
		addressesToSave = clientAddresses?.map( ( address ) => omit( address, 'id' ) );
	} else if ( isEmpty( clientAddresses ) ) {
		addressesToSave = [];
	} else if ( !isEmpty( clientAddresses ) && !isEmpty( dbAddresses ) ) {
		const commonAddresses = intersectionWith( dbAddresses, clientAddresses, ( a, b ) => a.line1 === b.line1 );
		const newAddresses = differenceWith( clientAddresses, dbAddresses, ( a, b ) => a.line1 === b.line1 );
		
		addressesToSave = [
			...commonAddresses,
			...newAddresses?.map( ( address: any ) => omit( address, 'id' ) ),
		];
	}
	return addressesToSave;
}

