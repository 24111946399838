import { mutateGraphQL } from '@/data/apollo';
import { CategoryRead, CategoryWrite } from '@/data/management/category.graphql';
import FormGraphqlProvider from '@/data/query/formGraphqlProvider';
import { useCloverLocations } from '@/pages/formSelects/locationSelect';
import { CheckPermissions, permissions } from '@/providers/auth/usePermissions';
import type { Category, CategoryType, MutationCategoryWriteArgs } from '@/types/schema';
import { useQueryClient } from '@tanstack/react-query';
import { pick } from 'lodash-es';
import { ComponentType, Fragment, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import CategoryFormClients from './clients';
import CategoryFormDetails from './details';
import CategoryFormItems from './items';

export default function CategoryForm( {
	id,
	onSubmit,
	Wrapper = Fragment,
	type,
}: {
	id?: string,
	onSubmit?: ( category: Category ) => void,
	type?: CategoryType,
	Wrapper: ComponentType<{
		name: string,
		children: ReactNode
	}>
} ) {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const [ cloverLocations ] = useCloverLocations();
	
	const validationSchema = yup.object().shape( {
		name       : yup
			.string()
			.required( t( 'management:enter-category-name' ) )
			.max( 64, t( 'management:category-name-is-too-long' ) ),
		type       : yup
			.string()
			.required( t( 'management:select-category-type' ) ),
		description: yup
			.string()
			.nullable()
			.max( 64, t( 'management:category-description-is-too-long' ) ),
		locations  : yup
			.array()
			.min( 1, t( 'management:must-select-a-location' ) ),
	} );
	
	return (
		<CheckPermissions redirect='/dashboard/management/categories' permissions={permissions.categories.write}>
			<FormGraphqlProvider<Category>
				id={id}
				queryKey='category'
				query={CategoryRead}
				initialValues={() => ( { items: [], clients: [], type: type || 'ITEM', location: cloverLocations?.[ 0 ] } )}
				validationSchema={validationSchema}
				onSubmit={async ( { id, ...values } ) => {
					const { categoryWrite } = await mutateGraphQL<MutationCategoryWriteArgs>( {
						mutation : CategoryWrite,
						variables: {
							id,
							method: 'Saved Changes',
							input : {
								...pick( values, [ 'name', 'description', 'sequence', 'type' ] ),
								items   : values.items?.map( ( { id } ) => id )?.filter( Boolean ) || [],
								clients : values.clients?.map( ( { id } ) => id )?.filter( Boolean ) || [],
								location: values.location?.id,
							},
						},
					} );
					onSubmit?.( categoryWrite );
					
					if ( values.location || values.externalId ) {
						// await postCategory( categoryWrite.id, values.location?.gateway as Gateway ).catch( ( e ) => {
						// 	console.log( e );
						// } );
					}
					await queryClient.invalidateQueries( [ 'company' ] );
					
				}}>
				{( formik ) => (
					<Wrapper name='Category'>
						<CategoryFormDetails/>
						{formik.values.type === 'CLIENT' ? <CategoryFormClients/> : <CategoryFormItems/>}
					</Wrapper>
				)}
			</FormGraphqlProvider>
		</CheckPermissions>
	);
}
