import CallEffect from '@/components/callEffect';
import Form from '@/components/form';
import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import PageSection from '@/components/page/section';
import { useGraphQL } from '@/data';
import { mutateGraphQL } from '@/data/apollo';
import { PricesRead, PricesWrite } from '@/data/management/price.graphql';
import idPick from '@/helpers/idPick';
import useAccountType from '@/helpers/useAccountType';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import useUserInfo from '@/providers/auth/useUserInfo';
import { MutationPricesWriteArgs, QueryPricesReadArgs } from '@/types/schema';
import { Paper, Stack, Typography } from '@mui/material';
import { isEqual } from 'lodash-es';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import FeeFormFields from './feeFormFields';

export default function Fees( {
	name,
	clientId,
}: {
	name: 'markupPrices' | 'savedPrices' | 'trade',
	clientId?: string
} ) {
	const { t } = useTranslation();
	const { staff } = useUserInfo();
	const confirmDialog = useConfirmDialog();
	const isCloverAccount = useAccountType( 'CLOVER' );
	const { enqueueSnackbar } = useSnackbar();
	
	const isMarkupPrices = name === 'markupPrices';
	const isSavedPrices = name === 'savedPrices';
	const isTrade = name === 'trade';
	
	const { data, refetch } = useGraphQL<QueryPricesReadArgs>( {
		query    : PricesRead,
		queryKey : [ 'pricesRead' ],
		variables: {
			options: {
				limit : 30,
				filter: isTrade ? {
						company: staff?.company?.id,
						client : clientId,
						isTrade: true,
					}
					: isMarkupPrices ? {
						company : staff?.company?.id,
						isMarkup: true,
					} : {
						company   : staff?.company?.id,
						isSavedFee: true,
					},
			},
		},
	}, { enabled: staff ? staff.permissions.includes( 'OWNER' ) : true } );
	
	const prices = data.pricesRead?.items;
	const cardFee = staff.company.metadata?.cardFee;
	const defaultFee = prices?.find( ( price ) => price.metadata?.companyFee );
	
	return (
		<Form
			initialValues={{ prices: prices || [] }}
			onSubmit={async ( values ) => {
				try {
					if ( isSavedPrices && cardFee > 0 && isCloverAccount && values.prices.find( ( price ) => price.metadata?.companyFee ) ) {
						const value = await confirmDialog( {
							title  : t( 'settings:confirm-save-fee' ),
							message: (
								<Stack spacing={3}>
									<Typography variant='h5'>
										{t( 'settings:exist-card-fee' )}
									</Typography>
									<Typography color='text.secondary'>
										{t( 'settings:exist-card-fee-desc' )}
									</Typography>
									<Typography color='text.secondary'>
										{t( 'settings:confirm-add-fee' )}
									</Typography>
								</Stack>
							),
						} );
						if ( !value ) return;
					}
					await mutateGraphQL<MutationPricesWriteArgs>( {
						mutation : PricesWrite,
						variables: {
							inputs: values.prices.map( ( price ) => ( {
								...idPick( price, [ 'name', 'isPercent', 'value', 'quantity', 'metadata' ] ),
								isMarkup  : isMarkupPrices,
								isSavedFee: isSavedPrices,
								isTrade   : isTrade,
								company   : staff?.company?.id || null,
								client    : clientId || undefined,
							} ) ),
						},
					} );
					
					enqueueSnackbar( 'Fees saved successfully', { variant: 'success' } );
					await refetch();
				} catch ( e ) {
					console.log( e );
				}
			}}>
			{( formik ) => (
				<Paper variant='borderless' sx={{ px: 2, pb: 1, mt: 2, bgcolor: 'background.default' }}>
					<PageSection
						primary={isTrade ? t( 'settings:trade-fee' ) : isMarkupPrices
							? t( 'settings:markup-fees' )
							: t( 'settings:saved-fee' )}
						secondary={isTrade
							? t( 'settings:trade-fee-desc' ) : isMarkupPrices
								? t( 'settings:markup-fees-desc' )
								: defaultFee?.name
									? t( 'settings:saved-fee-desc', { fee: `"${defaultFee.name}"` } )
									: 'Saved fees are fees that can be applied to documents.'}
						primaryTypographyProps={{ variant: 'h5' }}
						actions={(
							<AsyncLoadingButton
								disabled={isEqual( formik.values.prices, prices )}
								loading={formik.isSubmitting}
								sx={{ float: 'right' }}
								variant='contained'
								color='primary'
								onClick={async () => await formik.submitForm()}>
								Save
							</AsyncLoadingButton>
						)}>
						<CallEffect
							deps={[ prices ]}
							func={() => {
								formik.setFieldValue( 'prices', prices );
							}}
						/>
						<FeeFormFields name={name} refetch={refetch}/>
					</PageSection>
				</Paper>
			)}
		</Form>
	);
}

