import { mutateGraphQL } from '@/data/apollo';
import { ClientRead, ClientWrite } from '@/data/management/client.graphql';
import FormGraphqlProvider from '@/data/query/formGraphqlProvider';
import uploadFile from '@/data/uploadFile';
import idPick from '@/helpers/idPick';
import useAccountType from '@/helpers/useAccountType';
import ClientCredits from '@/pages/dashboard/management/clients/form/clientCredits';
import Msrps from '@/pages/dashboard/management/clients/form/msrps';
import SavedCards from '@/pages/dashboard/management/clients/form/savedCards';
import { useCloverLocations } from '@/pages/formSelects/locationSelect';
import { CheckPermissions, permissions } from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import type { Client, Gateway, MutationClientWriteArgs, Order } from '@/types/schema';
import { useQueryClient } from '@tanstack/react-query';
import { pick, toLower } from 'lodash-es';
import React, { ComponentType, Fragment, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { postClient } from '../clientUtils';
import ClientFormAddresses from './addresses';
import ClientFormDetails from './details';

export function useClientValidationSchema() {
	const { t } = useTranslation();
	return yup.object().shape( {
		customerId      : yup
			.string()
			.nullable()
			.max( 64, t( 'management:customerId-company-is-too-long' ) ),
		name            : yup
			.string()
			.nullable()
			.max( 64, t( 'management:client-company-is-too-long' ) ),
		contact         : yup
			.string()
			.required( t( 'management:enter-client-contact' ) )
			.max( 64, t( 'management:client-contact-is-too-long' ) ),
		email           : yup
			.string()
			.nullable()
			.email( t( 'common:house-accounts-email-invalid' ) )
			.max( 64, t( 'management:client-email-is-too-long' ) ),
		accountingEmails: yup
			.string()
			.nullable()
			.max( 255, t( 'management:client-email-is-too-long' ) ),
		phone           : yup
			.string()
			.nullable()
			.max( 22, t( 'management:client-phone-is-too-long' ) ),
		cell            : yup
			.string()
			.nullable()
			.max( 22, t( 'management:client-cell-is-too-long' ) ),
		addresses       : yup
			.array()
			.nullable()
			.of( yup
				.object()
				.shape( {
					line1     : yup
						.string()
						.required( t( 'common:enter-address' ) )
						.max( 250, t( 'common:house-accounts-address-long' ) ),
					line2     : yup
						.string()
						.nullable()
						.max( 64, t( 'common:floor-ste-is-too-long' ) ),
					city      : yup
						.string()
						.required( t( 'common:enter-city' ) )
						.max( 64, t( 'common:house-accounts-city-long' ) ),
					state     : yup
						.string()
						.required( t( 'common:enter-state' ) )
						.max( 64, t( 'common:house-accounts-state-long' ) ),
					country   : yup
						.string()
						.required( t( 'common:enter-country' ) )
						.max( 64, t( 'common:house-accounts-country-long' ) ),
					postalCode: yup
						.string()
						.required( t( 'common:enter-postal' ) )
						.max( 64, t( 'common:house-accounts-postal-long' ) ),
				} ),
			),
	} );
}

export default function ClientForm( {
	id,
	order,
	onSubmit,
	Wrapper = Fragment,
}: {
	id?: string,
	order?: Order,
	onSubmit?: ( client: Client, cloverAddress?: boolean ) => void,
	Wrapper?: ComponentType<{
		name: string,
		children: ReactNode,
		saveButtonText?: string | undefined | null
	}>
} ) {
	const { t } = useTranslation();
	const { user, staff } = useUserInfo();
	const validationSchema = useClientValidationSchema();
	const queryClient = useQueryClient();
	const isCloverAccount = useAccountType( 'CLOVER' );
	const [ cloverLocations ] = useCloverLocations();
	const location = order?.companyLocation?.gateway?.external === 'CLOVER'
		? order?.companyLocation
		: cloverLocations?.[ 0 ];
	
	return (
		<CheckPermissions redirect='/dashboard/management/clients' permissions={permissions.clients.write}>
			<FormGraphqlProvider<Client>
				id={id}
				queryKey='client'
				query={ClientRead}
				initialValues={() => ( { location } )}
				validationSchema={validationSchema}
				onSubmit={async ( { ...values } ) => {
					try {
						const { clientWrite } = await mutateGraphQL<MutationClientWriteArgs>( {
							mutation : ClientWrite,
							variables: {
								id    : values?.id,
								method: 'Saved Changes',
								input : {
									...pick( values, [
										'customerId',
										'name',
										'contact',
										'phone',
										'cell',
										'statement',
										'po',
										'balanceLimit',
										'terms',
									] ) as any,
									email           : values.email ? toLower( values.email ) : null,
									accountingEmails: values.accountingEmails ? toLower( values.accountingEmails )
										.trim()
										.replace( /\s+/g, '' ) : null,
									location        : values.location?.id || null,
									metadata        : {
										companyNumber    : values.metadata?.companyNumber,
										clientPrivateNote: values.metadata?.clientPrivateNote,
										exemptFromTax    : values.metadata?.exemptFromTax,
									},
									staff           : values.staff?.id || staff.id,
									logo            : await uploadFile( values.logo ),
									addresses       : values.addresses?.map( ( address ) => ( {
										metadata: address.metadata || {},
										...idPick( address, [
											'line1',
											'line2',
											'city',
											'state',
											'postalCode',
											'country',
											'externalId',
										] ),
										lng: address?.lng ? +address?.lng : null,
										lat: address?.lat ? +address?.lat : null,
									} ) ),
									clientCredits   : values.clientCredits?.map( ( credit ) => ( {
										...idPick( credit, [
											'amount',
											'expiration',
											'reason',
										] ),
										selected: credit?.selected || null,
										amount  : credit.amount ? +credit.amount : 0,
										staff   : credit.staff?.id || staff?.id,
										company : values.company?.id || credit.company?.id || staff.company?.id,
									} ) ),
									categories      : values.categories?.map( ( category ) => category?.id ),
									discounts       : values.discounts?.map( ( price ) => ( {
										...idPick( price, [ 'name', 'isPercent', 'value', 'quantity', 'metadata' ] ),
										isDiscount: true,
										client    : id,
									} ) ),
									msrps           : values.msrps?.filter( ( msrp ) => msrp.item )?.map( ( msrp ) => ( {
										...idPick( msrp, [ 'value' ] ),
										item   : msrp.item?.id || null,
										staff  : msrp.staff?.id || staff?.id,
										company: values.company?.id || staff?.company.id,
									} ) ),
								},
							},
						} );
						if ( !values.externalId && values.location?.gateway?.external === 'CLOVER' || values.externalId ) {
							await postClient( clientWrite.id, values.location?.gateway as Gateway ).catch( () => [] );
						}
						await queryClient.invalidateQueries( [ 'company' ] );
						onSubmit?.( clientWrite );
						
					} catch ( e ) {
						throw e;
					}
				}}>
				{( formik ) => (
					<Wrapper name='Client' saveButtonText={formik.values.externalId && t( 'common:sync-save' )}>
						<ClientFormDetails order={order}/>
						<ClientCredits/>
						{isCloverAccount && <SavedCards/>}
						<ClientFormAddresses/>
						<Msrps/>
					</Wrapper>
				)}
			</FormGraphqlProvider>
		</CheckPermissions>
	);
}
