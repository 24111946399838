import TextFieldInputLabel from '@/components/form/inputLabel';
import { CancelRounded as CancelRoundedIcon } from '@mui/icons-material';
import { Autocomplete, Box, Chip, ClickAwayListener, TextFieldProps } from '@mui/material';
import type { SyntheticEvent } from 'react';
import { useState } from 'react';
import FormattedTextField from '../../formattedTextField';

const isEmailValid = ( email: string ) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test( email );

export default function EmailStringField( {
	formEmails,
	setFormEmails,
	label,
	textFieldProps,
}: {
	formEmails: any,
	setFormEmails: ( emails: string[] ) => void,
	label?: string,
	textFieldProps?: TextFieldProps
} ) {
	
	const [ emails, setEmails ] = useState( formEmails || [] );
	
	const [ error, setError ] = useState( '' );
	
	const [ inputValue, setInputValue ] = useState( '' );
	const handleChange = ( event: SyntheticEvent, emails: string[] ) => {
		setEmails( emails );
		const invalidEmail = emails.find( ( email ) => !isEmailValid( email ) );
		invalidEmail ? setError( 'There are one more invalid emails' ) : setError( '' );
		setFormEmails( emails.filter( ( email ) => isEmailValid( email ) ) );
		event.persist();
	};
	
	const handleClickAway = () => {
		if ( inputValue.length > 0 && isEmailValid( inputValue ) ) {
			const newEmails = [ ...emails, inputValue ];
			setEmails( newEmails );
			setError( '' );
			setFormEmails( newEmails );
			setInputValue( '' );
		} else if ( inputValue.length > 0 && !isEmailValid( inputValue ) ) {
			setError( 'One or more invalid emails.' );
		}
	};
	
	return (
		<ClickAwayListener onClickAway={handleClickAway}>
			<Box>
				<TextFieldInputLabel label={label}/>
				<Autocomplete
					multiple
					freeSolo
					disableClearable
					fullWidth
					options={[] as string[]}
					renderTags={( emails, getTagProps ) => emails.map( ( email, index ) => (
						<Chip
							key={index}
							deleteIcon={<CancelRoundedIcon/>}
							label={email}
							sx={{
								'& .MuiChip-label'   : {
									textTransform: 'none',
								},
								'.MuiChip-deleteIcon': {
									color   : 'primary.main',
									fontSize: 19,
									ml      : .5,
								},
							}}
							variant='alpha'
							color={isEmailValid( email ) ? 'primary' : 'error'}
							{...getTagProps( { index } )}
						/>
					) )}
					value={emails}
					inputValue={inputValue?.toLowerCase()}
					renderInput={( params ) => (
						<FormattedTextField
							size='medium'
							error={Boolean( error )}
							{...params}
							inputProps={{
								...params.inputProps,
								onKeyDown: ( event ) => {
									switch ( event.code ) {
										case 'Tab':
										case 'Comma':
										case 'Space': {
											if ( inputValue.length > 0 ) {
												handleChange( event, emails.concat( [ inputValue ] ) );
												setInputValue( '' );
												event.preventDefault();
											}
											break;
										}
									}
								},
							}}
							helperText={error}
							variant='outlined'
							{...textFieldProps}
						/>
					)}
					onChange={handleChange}
					onInputChange={( event, newInputValue ) => {
						const options = newInputValue.split( /[ ,]+/ );
						const fieldValue = emails
							.concat( options )
							.map( ( x ) => x.trim() )
							.filter( ( x ) => x );
						
						if ( options.length > 1 ) {
							
							handleChange( event, fieldValue );
						} else {
							setInputValue( newInputValue );
						}
					}}
				/>
			</Box>
		</ClickAwayListener>
	);
}

