import { useMenu } from '@/providers/menu';
import { useModal } from '@/providers/modal';
import { Delete as DeleteIcon, Upload as UploadIcon } from '@mui/icons-material';
import type { AvatarProps, BadgeProps, SxProps } from '@mui/material';
import { Avatar, Badge, IconButton, ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material';
import { useField } from 'formik';
import type { ReactNode } from 'react';
import { useMemo, useState } from 'react';
import type { ReactCropperProps } from 'react-cropper';
import ImageUploadCropper from '../../fileUploading/imageUploadCropper';

export default function FormAvatar( {
	name,
	circle,
	children,
	disabled,
	iconButtonSX,
	badgeProps,
	cropperProps,
	...props
}: {
	name: string,
	circle?: boolean,
	children?: ReactNode,
	disabled?: boolean,
	badgeProps?: BadgeProps,
	iconButtonSX?: SxProps,
	cropperProps?: ReactCropperProps
} & AvatarProps ) {
	const [ , { value = null }, { setValue, setTouched } ] = useField( name );
	const { showModal } = useModal();
	const { showMenu } = useMenu();
	const imageSrc = useMemo( () => typeof value === 'string' || !value
		? value as string
		: URL.createObjectURL( value ), [ value ] );
	
	const [ original, setOriginal ] = useState<Blob | File | string>();
	
	return (
		<Badge
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			invisible={disabled}
			badgeContent={(
				<IconButton
					sx={{
						'mb'     : 1.5,
						'bgcolor': 'primary.main',
						'color'  : 'primary.contrastText',
						':hover' : { filter: 'brightness(80%)', bgcolor: 'primary.main' },
						...iconButtonSX,
					}}
					onClick={( e ) => {
						e.preventDefault();
						showMenu( ( { closeMenu } ) => (
							<MenuList>
								<MenuItem
									dense
									onClick={() => {
										closeMenu();
										showModal( ImageUploadCropper, undefined, {
											circle     : circle,
											initialFile: original || value,
											onSave     : async ( blob, original ) => {
												setOriginal( original );
												setTouched( true );
												setValue( blob );
											},
											...cropperProps,
										} );
									}}>
									<ListItemIcon>
										<UploadIcon sx={{ fontSize: 18 }}/>
									</ListItemIcon>
									<ListItemText primary='Upload new Image'/>
								</MenuItem>
								{value && (
									<MenuItem
										dense
										onClick={() => {
											setValue( null );
											closeMenu();
										}}>
										<ListItemIcon>
											<DeleteIcon sx={{ fontSize: 18, color: 'error.main' }}/>
										</ListItemIcon>
										<ListItemText primary='Delete Image'/>
									</MenuItem>
								)}
							</MenuList>
						), e.currentTarget, {
							anchorOrigin   : { vertical: 'bottom', horizontal: 'center' },
							transformOrigin: { vertical: 'top', horizontal: 'center' },
							sx             : { mt: .5 },
							MenuListProps  : {
								sx: { p: 0, minWidth: 150 },
							},
						} );
					}}>
					<UploadIcon sx={{ fontSize: 18 }}/>
				</IconButton>
			)}
			{...badgeProps}>
			<Avatar
				src={imageSrc}
				{...props}
				sx={{
					width       : '100%',
					borderRadius: 2,
					p           : 0,
					minWidth    : 50,
					minHeight   : 50,
					fontSize    : 45,
					...props.sx,
				}}>
				{children}
			</Avatar>
		</Badge>
	);
}
