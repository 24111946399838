import EnhancedList from '@/components/enhancedDisplay/enhancedList';
import FormAddress from '@/components/form/fields/address';
import { Box } from '@mui/material';
import { useField } from 'formik';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';

export default function ClientFormAddresses() {
	const { t } = useTranslation();
	const [ , { value }, { setValue } ] = useField<any[]>( 'addresses' );
	
	return (
		<EnhancedList
			sortable={{}}
			title={t( 'common:addresses' )}
			data={value}
			setData={( data ) => setValue( data )}
			extraData={1}
			editable={{
				newData: () => ( {
					id        : nanoid(),
					line1     : '',
					line2     : '',
					city      : '',
					state     : '',
					country   : '',
					postalCode: '',
					lat       : '',
					lng       : '',
				} ),
			}}
			renderRow={( address, index ) => (
				<Box py={1}>
					<FormAddress name={`addresses.${index}`}/>
				</Box>
			)}
		/>
	);
}
