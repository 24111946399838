import EnhancedDisplay from '@/components/enhancedDisplay';
import OverflowTypography from '@/components/overflowTypography';
import currencyFormat from '@/helpers/currencyFormat';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import NewClientCreditModal from '@/modals/newClientCreditModal';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal } from '@/providers/modal';
import { Client, ClientCredit } from '@/types/schema';
import { Close as CloseIcon, Edit as EditIcon } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { stayOpen } from '../../../commerce/components/tableHelpers';
import RequirePinCodeModal from '../../../settings/security/requirePinCodeModal';
import { deletePinCode, getEditedCredits } from './creditPinCode';

export default function CreditDetails( {
	client,
	credits,
	setClientCredits,
}: {
	client: Client,
	credits: ClientCredit[],
	setClientCredits: ( credits: ClientCredit[] ) => void
} ) {
	const { t } = useTranslation();
	const { staff } = useUserInfo();
	const { showModal } = useModal();
	const { enqueueSnackbar } = useSnackbar();
	const confirmDialog = useConfirmDialog();
	const company = staff.company;
	
	return (
		<EnhancedDisplay<ClientCredit>
			pageSectionProps={{
				primary  : t( 'common:store-credit' ),
				secondary: t( 'common:store-credit-detail' ),
				actions  : [ {
					name       : t( 'common:create-credit' ),
					details    : !Boolean( client?.id )
						? 'Please save the client first to create the store credits.'
						: '',
					buttonProps: { disabled: !Boolean( client?.id ) },
					onClick    : () => {
						if ( company.hasPinCode && company.pinCodeData?.onApplyCredits && company.pinCodeData?.createCredits ) {
							showModal( RequirePinCodeModal, {
								maxWidth: 'sm',
								onClose : ( event, reason ) => stayOpen( event, reason ),
							}, {
								onSubmit: ( isApproved ) => {
									if ( isApproved ) {
										showModal( NewClientCreditModal, { maxWidth: 'sm' }, {
											client,
											onSubmit: ( clientCredit: ClientCredit ) => setClientCredits( [ ...credits || [],
											                                                                clientCredit ] ),
										} );
									}
								},
							} );
						} else {
							showModal( NewClientCreditModal, {
								maxWidth: 'sm',
								onClose : ( event, reason ) => stayOpen( event, reason ),
							}, {
								client,
								onSubmit: ( clientCredit: ClientCredit ) => setClientCredits( [ ...credits || [],
								                                                                clientCredit ] ),
							} );
						}
					},
				} ],
			}}
			data={credits || []}
			listProps={{
				dense    : true,
				renderRow: ( credit ) => (
					<Fragment>
						<Typography>
							{credit.expiration ? `${format( new Date( credit.expiration ), 'PP' )}` : '-'}
						</Typography>
						<Typography>
							{credit.amount}
						</Typography>
						<Typography>
							{credit.selected ? t( 'common:credit' ) : t( 'common:availability' )}
						</Typography>
						<OverflowTypography>
							{credit.reason}
						</OverflowTypography>
					</Fragment>
				),
			}}
			tableProps={{
				hover    : false,
				size     : 'small',
				headers  : [
					t( 'common:expires' ),
					t( 'common:credit-amount' ),
					t( 'common:reason' ),
					t( 'common:availability' ),
					t( 'common:action' ) ],
				columns  : ( credit, index ) => [
					`${credit.expiration
						? `${format( new Date( credit.expiration ), 'PP' )}`
						: 'Never'}`,
					<Typography key='amount'>
						{currencyFormat( credit.amount )}
					</Typography>,
					<OverflowTypography key='reason' sx={{ width: 200 }}>
						{credit.reason}
					</OverflowTypography>,
					credit.selected ? t( 'management:used' ) : t( 'common:availability' ),
					<Stack key='action' spacing={1} direction='row' sx={{ justifyContent: 'right' }}>
						<IconButton
							key={credit.id}
							disabled={credit.selected}
							color='primary'
							onClick={( e ) => {
								e.stopPropagation();
								if ( company.hasPinCode && company.pinCodeData?.onApplyCredits && company.pinCodeData?.editCredits ) {
									showModal( RequirePinCodeModal, { maxWidth: 'sm' }, {
										onSubmit: ( isApproved ) => {
											if ( isApproved ) {
												showModal( NewClientCreditModal, { maxWidth: 'sm' }, {
													id      : credit.id,
													client,
													onSubmit: ( credit ) => {
														const editedCredits = getEditedCredits( credits, credit, index );
														setClientCredits( editedCredits );
													},
												} );
											}
										},
									} );
								} else {
									showModal( NewClientCreditModal, { maxWidth: 'sm' }, {
										id      : credit.id,
										client,
										onSubmit: ( credit ) => {
											const editedCredits = getEditedCredits( credits, credit, index );
											setClientCredits( editedCredits );
										},
									} );
								}
							}}>
							<EditIcon sx={{ fontSize: 18 }}/>
						</IconButton>
						<IconButton
							key={credit.id + credit.id}
							color='error'
							disabled={credit.selected}
							onClick={async () => {
								const confirmDelete = await confirmDialog( {
									title  : t( 'common:delete' ),
									message: t( 'common:delete-confirmation' ),
								} );
								if ( !confirmDelete ) return;
								
								if ( company.hasPinCode && company.pinCodeData?.onApplyCredits && company.pinCodeData?.deleteCredits ) {
									showModal( RequirePinCodeModal, { maxWidth: 'sm' }, {
										onSubmit: async ( isApproved ) => {
											if ( isApproved ) {
												const remainingCredits = await deletePinCode( credits, credit );
												setClientCredits( remainingCredits );
												enqueueSnackbar( t( 'common:credit-remove-success' ), { variant: 'error' } );
											}
										},
									} );
								} else {
									const remainingCredits = await deletePinCode( credits, credit );
									setClientCredits( remainingCredits );
									enqueueSnackbar( t( 'common:credit-remove-success' ), { variant: 'success' } );
								}
							}}>
							<CloseIcon/>
						</IconButton>
					</Stack>,
				].filter( Boolean ),
				cellProps: [
					{ align: 'left' },
					{ align: 'left' },
					{ align: 'left' },
					{ align: 'left' },
					{ align: 'right' },
				],
			}}
		/>
	);
}
