import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { Box, Typography } from '@mui/material';
import 'cropperjs/dist/cropper.min.css';
import imageCompression from 'browser-image-compression';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import type { ReactCropperProps } from 'react-cropper';
import { Cropper } from 'react-cropper';
import { useDropzone } from 'react-dropzone';

export type ImageUploadCropperProps = {
	initialFile?: File | Blob | string,
	ratio?: number,
	circle?: boolean,
	maxMB?: number,
	onSave?: ( blob: Blob, original: File | Blob | string ) => Promise<void>
} & ReactCropperProps;

export default function ImageUploadCropper( {
	initialFile,
	ratio,
	circle,
	maxMB = 10,
	onSave,
	...props
}: ImageUploadCropperProps ) {
	const { enqueueSnackbar } = useSnackbar();
	
	const [ files, setFiles ] = useState<File[]>( [] );
	const [ cropper, setCropper ] = useState<Cropper>();
	
	const { getRootProps, getInputProps } = useDropzone( {
		multiple: false,
		accept  : { 'image/*': [ '.jpeg', '.png', '.jpg', '.gif', '.bmp' ] },
		onDrop  : ( newFiles ) => {
			if ( newFiles.some( ( file ) => file.size > maxMB * 1024 * 1024 ) ) {
				enqueueSnackbar( `File size too large, max ${maxMB} MB` );
				return;
			}
			setFiles( newFiles );
		},
	} );
	const imageSrc = useMemo( () => !files.length
		? typeof initialFile === 'string' ? initialFile : initialFile && URL.createObjectURL( initialFile )
		: URL.createObjectURL( files[ 0 ] ), [ files ] );
	
	return (
		<ResponsiveModalContainer
			title='Select Image'
			onSave={async () => {
				if ( !cropper ) return;
				const blob = await new Promise<Blob>( ( resolve ) =>
					cropper.getCroppedCanvas()?.toBlob( ( b ) => b ? resolve( b ) : null ) );
				const file = new File( [ blob ], 'img.png', { type: 'image/png' } );
				const compressed = await imageCompression( file, {
					maxSizeMB       : 1,
					maxWidthOrHeight: 800,
				} );
				const compressedBlob = new Blob( [ compressed ], { type: 'image/png' } );
				await onSave?.( compressedBlob, files[ 0 ] || initialFile );
			}}>
			<Box
				{...getRootProps()}
				sx={{
					'p'           : 2,
					'textAlign'   : 'center',
					'borderColor' : 'divider',
					'border'      : '1px dashed',
					'borderRadius': 2,
					'transition'  : '.3s',
					'color'       : 'text.secondary',
					':hover'      : {
						color      : 'primary.main',
						bgcolor    : 'alpha.primary',
						borderColor: 'primary.main',
						cursor     : 'pointer',
					},
				}}>
				<input {...getInputProps()}/>
				<Typography sx={{ color: 'text.secondary' }}>
					Drag 'n' drop a file here, or click to select a file
				</Typography>
			</Box>
			<Box
				hidden={!imageSrc}
				sx={circle ? {
					'.cropper-view-box': { borderRadius: '50%' },
					'.cropper-face'    : { bgcolor: 'inherit !important' },
				} : undefined}>
				<Cropper
					guides
					aspectRatio={ratio}
					src={imageSrc}
					viewMode={1}
					minCropBoxHeight={10}
					minCropBoxWidth={10}
					background={false}
					checkOrientation={false}
					onInitialized={( instance ) => setCropper( instance )}
					{...props}
				/>
			</Box>
		</ResponsiveModalContainer>
	);
}
