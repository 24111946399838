import { useField } from 'formik';
import EnhancedDisplay from '../../enhancedDisplay';
import type { EnhancedDisplayProps, EnhancedListProps, EnhancedTableProps } from '../../enhancedDisplay/helpers';

export default function FormList<T>( {
	name,
	filterBy,
	...props
}: {
	name: string,
	filterBy?: ( value: T[] ) => T[]
} & Omit<EnhancedDisplayProps<T> & {
	listProps: EnhancedListProps<T>,
	tableProps: EnhancedTableProps<T>
}, 'data' | 'setData'> ) {
	const [ , { value = [] }, { setValue, setTouched } ] = useField( name );
	
	return (
		<EnhancedDisplay
			data={filterBy ? filterBy( value ) : value}
			setData={( value ) => {
				setTouched( true );
				setValue( value );
			}}
			{...props}
		/>
	);
}
