import FormSelect from '@/components/form/fields/select';
import FormTextField from '@/components/form/fields/textField';
import PageSection from '@/components/page/section';
import useAccountType from '@/helpers/useAccountType';
import LocationSelect from '@/pages/formSelects/locationSelect';
import { Grid, MenuItem } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function CategoryFormDetails() {
	const { t } = useTranslation();
	const isCloverAccount = useAccountType( 'CLOVER' );
	
	return (
		<PageSection primary={t( 'common:details' )}>
			<Grid container spacing={1} pt={1}>
				<Grid item xs={12} md={6}>
					<FormTextField
						fullWidth
						name='name'
						label={t( 'common:name' )}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<FormTextField
						fullWidth
						multiline
						name='description'
						label={t( 'common:description' )}
					/>
				</Grid>
				<Grid item xs={12} md={6} sx={{ mt: 2 }}>
					<FormTextField
						fullWidth
						type='number'
						InputProps={{ inputProps: { min: 0, max: 32000 } }}
						format={( value ) => Math.abs( +value ) || 0}
						helperText={t( 'common:category-tooltip' )}
						name='sequence'
						label={t( 'common:priority-number' )}
						onFocus={( e ) => e.target.select()}
					/>
				</Grid>
				<Grid item xs={12} md={6} sx={{ mt: 2 }}>
					<FormSelect
						fullWidth
						name='type'
						label={t( 'common:type' )}
						defaultValue=''>
						<MenuItem value='CLIENT'>Client</MenuItem>
						<MenuItem value='ITEM'>Item</MenuItem>
					</FormSelect>
				</Grid>
				{isCloverAccount && (
					<Grid item xs={12}>
						<LocationSelect
							name='location'
							label='Select Location'
							variables={{ options: { filter: { gateway: { active: true, external: 'CLOVER' } } } }}
							onAdd={undefined}
						/>
					</Grid>
				)}
			</Grid>
		</PageSection>
	);
}
