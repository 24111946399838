import FormAvatar from '@/components/form/fields/avatar';
import EmailStringField from '@/components/form/fields/emailStringField';
import FormPhoneField from '@/components/form/fields/phoneField';
import FormSwitch from '@/components/form/fields/switch';
import FormTextField from '@/components/form/fields/textField';
import { ListItemComp } from '@/components/listItem';
import PageSection from '@/components/page/section';
import useAccountType from '@/helpers/useAccountType';
import CategorySelect from '@/pages/formSelects/categorySelect';
import type { DeepPartial } from '@/types/deepPartial';
import { Client, Order } from '@/types/schema';
import { Person as PersonIcon } from '@mui/icons-material';
import { Grid, InputAdornment, ListItem, ListItemText, Tooltip } from '@mui/material';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LocationSelect from '../../../../formSelects/locationSelect';

export default function ClientFormDetails( { order }: { order?: Order } ) {
	const formik = useFormikContext<DeepPartial<Client>>();
	const isCloverAccount = useAccountType( 'CLOVER' );
	const { t } = useTranslation();
	
	const isOrderSyncedAndHasPayments = order?.externalId && !isEmpty( order?.payments );
	
	return (
		<PageSection primary={t( 'common:details' )}>
			<Grid container item spacing={2}>
				<Grid item xs={12} sm={8}>
					<FormAvatar
						badgeProps={{ overlap: 'circular' }}
						name='logo'
						sx={{
							width        : 80,
							height       : 80,
							fontSize     : 25,
							borderRadius : '100%',
							textTransform: 'uppercase',
						}}>
						{formik.values.contact?.split( ' ' ).filter( Boolean ).map( ( v ) => v[ 0 ] ).join( '' )
							|| <PersonIcon sx={{ fontSize: { xs: 20, md: 50 } }}/>}
					</FormAvatar>
				</Grid>
				<Grid item xs={12} sm={4}>
					<FormTextField
						fullWidth
						name='customerId'
						label={`${t( 'common:customerId' )}`}
					/>
				</Grid>
				<Grid item container xs={12} spacing={2}>
					<Grid item xs={12}>
						<FormTextField
							fullWidth
							name='name'
							label={`${t( 'common:company' )} ${t( 'common:name' )}`}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormTextField
							fullWidth
							required
							name='contact'
							label={t( 'common:full-name' )}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormTextField
							fullWidth
							name='email'
							label={t( 'common:email' )}
							type='email'
							autoComplete='email'
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormPhoneField
							name='phone'
							label={t( 'common:phone' )}
							onChange={( value, data, event, formattedValue ) => {
								formik.setFieldValue( 'phone', formattedValue );
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormPhoneField
							name='cell'
							label={t( 'common:cell' )}
							onChange={( value, data, event, formattedValue ) => {
								formik.setFieldValue( 'cell', formattedValue );
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormTextField
							fullWidth
							label='Balance limit'
							type='number'
							name='balanceLimit'
							InputProps={{
								startAdornment: <InputAdornment position='start'>$</InputAdornment>,
								inputProps    : { min: 0 },
							}}
							format={( value ) => Math.abs( +value ) || 0}
							onFocus={( event ) => event.target.select()}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Tooltip title='Set a default due date for this client. For example, if you enter "14," the due date will automatically default to 14 days from the invoice issue date for this client, though you can modify it if needed.'>
							<FormTextField
								fullWidth
								label='Terms'
								type='number'
								name='terms'
								InputProps={{ inputProps: { min: 0 } }}
								format={( value ) => Math.abs( +value ) || 0}
								onFocus={( event ) => event.target.select()}
							/>
						</Tooltip>
					</Grid>
					<Grid item xs={12} sm={6}>
						<CategorySelect
							multiple
							fullWidth
							type='CLIENT'
							name='categories'
						/>
					</Grid>
					<Grid item xs={12}>
						<EmailStringField
							label={`${t( 'common:acc-email' )} (separate emails by pressing spacebar)`}
							formEmails={formik.values.accountingEmails?.split( ',' ) || []}
							setFormEmails={( emails ) => formik.setFieldValue( 'accountingEmails', emails?.join( ',' ) )}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormTextField
							fullWidth
							multiline
							name='po'
							label={`${t( 'common:client' )} ${t( 'common:po' )}`}
							placeholder={t( 'common:add-client-po' )}
							variant='outlined'
							inputProps={{ maxLength: 5000 }}
							rows={4}
							helperText={t( 'common:add-client-po-details' )}
						/>
					</Grid>
					{isCloverAccount && (
						<Grid item xs={12}>
							<LocationSelect
								name='location'
								label='Select Location'
								variables={{ options: { filter: { gateway: { active: true, external: 'CLOVER' } } } }}
								onAdd={undefined}
							/>
						</Grid>
					)}
				</Grid>
				<Grid item xs={12}>
					<FormTextField
						fullWidth
						multiline
						name='metadata.clientPrivateNote'
						label={t( 'management:private-note' )}
						placeholder={t( 'common:private-note-detail' )}
						variant='outlined'
						inputProps={{ maxLength: 3000 }}
						rows={3}
						helperText={t( 'common:private-note-visible' )}
					/>
				</Grid>
				<Grid item xs={12}>
					<ListItem disableGutters>
						<ListItemText
							primary={t( 'management:auto-Statement' )}
							secondary={t( 'common:send-statement' )}
							primaryTypographyProps={{ fontWeight: 'bold' }}
						/>
						<FormSwitch name='statement'/>
					</ListItem>
				</Grid>
				<Grid item xs={12}>
					<ListItemComp
						primary={t( 'common:exempt-tax-action' )}
						secondary={t( 'common:exempt-tax-action-detail' )}
						primaryTypographyProps={{ fontWeight: 'bold' }}
						rightComp={( <FormSwitch name='metadata.exemptFromTax'/> )}
						tooltip={isOrderSyncedAndHasPayments && t( 'management:changing-tax-exempt' )}
						listItemProps={{ disablePadding: true }}
					/>
				</Grid>
			</Grid>
		</PageSection>
	);
}
