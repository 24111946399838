import { styled, Switch } from '@mui/material';

export const DollarPercentageSwitch = styled( Switch )( ( { theme } ) => ( {
	'width'                  : 55,
	'height'                 : 34,
	'padding'                : 7,
	'& .MuiSwitch-switchBase': {
		'margin'       : 1,
		'padding'      : 0,
		'top'          : 3,
		'transform'    : 'translateX(6px)',
		'&.Mui-checked': {
			'color'                    : '#fff',
			'transform'                : 'translateX(22px)',
			'& .MuiSwitch-thumb:before': {
				backgroundImage: 'url(https://www.svgrepo.com/show/47024/percentage.svg)',
				right          : 6,
			},
			'& + .MuiSwitch-track'     : {
				opacity        : 1,
				backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
			},
		},
	},
	'& .MuiSwitch-thumb'     : {
		'backgroundColor': '#2196f3',
		'width'          : 25,
		'height'         : 25,
		'&:before'       : {
			content           : '\'\'',
			position          : 'absolute',
			width             : '50%',
			height            : '100%',
			left              : 6,
			top               : 0,
			backgroundRepeat  : 'no-repeat',
			backgroundPosition: 'center',
			backgroundImage   : 'url(https://www.svgrepo.com/show/143180/dollar.svg)',
			backgroundSize    : 'contain',
		},
	},
	'& .MuiSwitch-track'     : {
		opacity        : 1,
		backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
		borderRadius   : 20 / 2,
	},
	'div'                    : {
		bgcolor    : 'red !important',
		color      : 'red',
		width      : 300,
		borderWidth: 5,
	},
} ) );
