import { Client } from '@/types/schema';
import { useField, useFormikContext } from 'formik';
import React from 'react';
import CreditDetails from './creditsDetails';

export default function ClientCredits() {
	const formik = useFormikContext<Client>();
	const [ , { value }, { setValue } ] = useField( 'clientCredits' );
	
	return (
		<CreditDetails client={formik.values} credits={value} setClientCredits={setValue}/>
	);
}
