import Attachment from '@/components/fileUploading/attachment';
import FormSelectTable from '@/components/form/fields/formSelectTable';
import PageSection from '@/components/page/section';
import { ItemsRead } from '@/data/management/item.graphql';
import type { DeepPartial } from '@/types/deepPartial';
import type { Item, QueryItemsReadArgs } from '@/types/schema';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function CategoryFormItems() {
	const { t } = useTranslation();
	
	return (
		<PageSection primary={t( 'common:items' )}>
			<Box>
				<FormSelectTable<Item, QueryItemsReadArgs, DeepPartial<Item>>
					disableCloseOnSelect
					name='items'
					blurOnSelect={false}
					textFieldProps={{ placeholder: t( 'common:search-by-name' ) }}
					queryKey='items'
					query={ItemsRead}
					// subscription={{ ITEM: 'COMPANY' }}
					getOptionLabel={( option: Item ) => `${option.name} ${option?.uoms?.length
						? `(${option?.uoms.map( ( uom ) => uom.name )
							.join( ', ' )})`
						: ''}`}
					checkDuplicate={( item, newValue: Item ) => item?.id === newValue?.id}
					tableProps={{
						hideAddIcon: true,
						headers    : [ t( 'management:image' ),
						               t( 'common:name' ),
						               t( 'management:taxable' ),
						               t( 'common:inventory' ) ],
						columns    : ( item ) => [
							<Attachment
								key='image'
								removeDownload
								src={item.image}
								imageSX={{ width: 40, height: 40, objectFit: 'cover', mt: 1 }}
							/>,
							item.name,
							item.taxable,
							item.isInventory,
						],
					}}
				/>
			</Box>
		</PageSection>
	);
}
