import FormSelectTable from '@/components/form/fields/formSelectTable';
import PageSection from '@/components/page/section';
import { ClientsRead } from '@/data/management/client.graphql';
import type { DeepPartial } from '@/types/deepPartial';
import type { Client, Item, QueryClientsReadArgs } from '@/types/schema';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function CategoryFormClients() {
	const { t } = useTranslation();
	
	return (
		<PageSection primary={t( 'common:clients' )}>
			<Box>
				<FormSelectTable<Client, QueryClientsReadArgs, DeepPartial<Client>>
					disableCloseOnSelect
					name='clients'
					blurOnSelect={false}
					textFieldProps={{
						placeholder: t( 'common:search-by-name' ),
					}}
					queryKey='clients'
					query={ClientsRead}
					getOptionLabel={( client: Client ) => `${client.name} (${client.email || client.contact})`}
					checkDuplicate={( client, newValue: Item ) => client?.id === newValue?.id}
					tableProps={{
						hideAddIcon: true,
						headers    : [ t( 'common:name' ),
						               t( 'common:email' ),
						               t( 'common:contact' ) ],
						columns    : ( client ) => [
							client.name,
							client.email,
							client.contact,
						],
					}}
				/>
			</Box>
		</PageSection>
	);
}
