import FormSelectTable from '@/components/form/fields/formSelectTable';
import FormTextField from '@/components/form/fields/textField';
import PageSection from '@/components/page/section';
import { ItemsRead } from '@/data/management/item.graphql';
import currencyFormat from '@/helpers/currencyFormat';
import useUserInfo from '@/providers/auth/useUserInfo';
import { DeepPartial } from '@/types/deepPartial';
import { Category, Client, Item, Msrp, QueryItemsReadArgs } from '@/types/schema';
import { InputAdornment, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { round } from 'lodash-es';
import { nanoid } from 'nanoid';
import React from 'react';

export default function Msrps() {
	const formik = useFormikContext<Client & { itemCategories: Category[] }>();
	const { staff } = useUserInfo();
	const cashDiscount = formik.values.company?.metadata?.hasCloverCashDiscount
		? round( staff?.company.metadata?.cashDiscount || 0, 2 ) / 100 : 0;
	
	return (
		<PageSection primary='Special Prices'>
			{/*<Grid container>*/}
			{/*	<Grid item lg={6} xs={12}>*/}
			{/*		<CategorySelect*/}
			{/*			fullWidth*/}
			{/*			multiple*/}
			{/*			label='Select Items by Categories'*/}
			{/*			type='ITEM'*/}
			{/*			name='itemCategories'*/}
			{/*			onAdd={undefined}*/}
			{/*			onChange={async ( e, value: Category[] ) => {*/}
			{/*				let savedMsrps: Msrp[] = formik.values.msrps;*/}
			{/*				*/}
			{/*				// remove msrps with deleted item categories*/}
			{/*				const deletedItemCategories = differenceWith( formik.values.itemCategories || [], value, ( a: Category,*/}
			{/*					b: Category ) => a.id === b.id );*/}
			{/*				*/}
			{/*				if ( !isEmpty( deletedItemCategories ) ) {*/}
			{/*					const deletedItems = deletedItemCategories.map( ( { items } ) => items ).flat();*/}
			{/*					savedMsrps = differenceWith( savedMsrps || [], deletedItems, ( a: Msrp,*/}
			{/*						b: Item ) => a.item?.id === b.id );*/}
			{/*					await formik.setFieldValue( 'msrps', savedMsrps );*/}
			{/*				}*/}
			{/*				*/}
			{/*				// add msrps with item categories*/}
			{/*				if ( value?.length ) {*/}
			{/*					const { categoriesRead } = await queryGraphQL<QueryCategoriesReadArgs>( {*/}
			{/*						query    : CategoriesReadForMSRP,*/}
			{/*						variables: { options: { filter: { id: { $in: value.map( ( { id } ) => id ) } } } },*/}
			{/*					} );*/}
			{/*					*/}
			{/*					const categoryItems = categoriesRead.items.map( ( { items } ) => items ).flat();*/}
			{/*					*/}
			{/*					const itemsToAdd = differenceWith( categoryItems, savedMsrps, ( a: Item,*/}
			{/*						b: Msrp ) => a.id === b.item?.id );*/}
			{/*					if ( !isEmpty( itemsToAdd ) ) {*/}
			{/*						const msrps = itemsToAdd.map( ( item ) => ( {*/}
			{/*							id   : nanoid(),*/}
			{/*							value: item.uoms?.find( ( uom ) => uom.selected )?.price || item.uoms?.[ 0 ]?.price || 0,*/}
			{/*							item,*/}
			{/*							staff,*/}
			{/*						} ) );*/}
			{/*						await formik.setFieldValue( 'msrps', [ ...savedMsrps || [], ...msrps ] );*/}
			{/*					}*/}
			{/*				}*/}
			{/*			}}*/}
			{/*		/>*/}
			{/*	</Grid>*/}
			{/*</Grid>*/}
			<Typography sx={{ fontWeight: 'bold' }}>Search Items</Typography>
			<FormSelectTable<Item, QueryItemsReadArgs, DeepPartial<Msrp>>
				disableCloseOnSelect
				name='msrps'
				textFieldProps={{ placeholder: 'Search By Name...' }}
				queryKey={[ 'items' ]}
				query={ItemsRead}
				blurOnSelect={false}
				variables={{
					options: {
						limit : 20,
						filter: {
							isHidden: null,
							uoms    : { removed: null },
							name    : { $ne: null },
						},
					},
				}}
				selectedOption={( item, msrps ) => Boolean( msrps?.find( ( msrp ) => msrp.item?.id === item.id ) )}
				getOptionLabel={( { name, description, uoms }: Item ) => [
					name,
					description,
					uoms?.length ? ` - ${uoms.filter( ( uom ) => !uom.removed )
							.map( ( uom ) => `(${currencyFormat( uom.price + uom.price * cashDiscount )}) ${uom.code
								? `(${uom.code})`
								: ''} ${uom.sku ? `(${uom.sku})` : ''}` )
							.join( ' ' )}`
						: '' ].join( ' ' ).trim()}
				tableProps={{
					hideAddIcon: true,
					headers    : [ 'Name', 'Price' ],
					columns    : ( msrp, index ) => [
						msrp.item?.name || '-',
						<FormTextField
							key='value'
							type='number'
							name={`msrps.${index}.value`}
							InputProps={{
								startAdornment: <InputAdornment position='start'>$</InputAdornment>,
								inputProps    : { min: 0 },
							}}
							format={( value ) => Math.abs( +value ) || 0}
							onFocus={( event ) => event.target.select()}
						/>,
					],
				}}
				onChange={async ( event, value: Item ) => {
					if ( value ) {
						if ( formik.values.msrps?.find( ( msrp ) => msrp.item?.id === value.id ) ) {
							await formik.setFieldValue( 'msrps', formik.values.msrps?.filter( ( msrp ) => msrp.item?.id !== value.id ) );
						} else {
							await formik.setFieldValue( 'msrps', [ ...formik.values.msrps || [], {
								id   : nanoid(),
								value: value.uoms?.find( ( uom ) => uom.selected )?.price || value.uoms?.[ 0 ]?.price || 0,
								item : value,
								staff,
							} ] );
						}
					}
				}}
			/>
		</PageSection>
	);
}
